<!-- TermsAndConditions.vue -->
<template>
    <div class="terms-and-conditions" :class="{ rtl: isHebrew }">
      <button class="toggle-btn" @click="toggleLanguage">
        <i class="material-icons">language</i>
        {{ isHebrew ? 'English' : 'עברית' }}
      </button>
  
      <!-- Title & Last Updated -->
      <h1>{{ termsContent.title }}</h1>
      <p>{{ termsContent.lastUpdated }}</p>
      <br />
  
      <!-- 1. Who We Are -->
      <h2>{{ termsContent.whoWeAreTitle }}</h2>
      <p v-html="termsContent.whoWeAreText"></p>
      <br />
  
      <!-- 2. Use of Our Website -->
      <h2>{{ termsContent.useWebsiteTitle }}</h2>
      <ul v-if="termsContent.useWebsitePoints">
        <li v-for="(point, i) in termsContent.useWebsitePoints" :key="i">
          {{ point }}
        </li>
      </ul>
      <p v-if="termsContent.useWebsiteNote">
        {{ termsContent.useWebsiteNote }}
      </p>
      <br />
  
      <!-- 3. Services & Website Content (Table) -->
      <h2>{{ termsContent.servicesTitle }}</h2>
      <div v-html="termsContent.servicesTable"></div>
      <p>{{ termsContent.servicesNote }}</p>
      <br />
  
      <!-- 4. Job Applications -->
      <h2>{{ termsContent.jobAppsTitle }}</h2>
      <p v-html="termsContent.jobAppsText"></p>
      <br />
  
      <!-- 5. Privacy Policy -->
      <h2>{{ termsContent.privacyPolicyTitle }}</h2>
      <p v-html="termsContent.privacyPolicyText"></p>
      <br />
  
      <!-- 6. Intellectual Property Rights -->
      <h2>{{ termsContent.ipTitle }}</h2>
      <p>{{ termsContent.ipText }}</p>
      <br />
  
      <!-- 7. Third-Party Services -->
      <h2>{{ termsContent.thirdPartiesTitle }}</h2>
      <p v-html="termsContent.thirdPartiesText"></p>
      <br />
  
      <!-- 8. Limitation of Liability -->
      <h2>{{ termsContent.liabilityTitle }}</h2>
      <p>{{ termsContent.liabilityText }}</p>
      <br />
  
      <!-- 9. Changes to Terms -->
      <h2>{{ termsContent.changesTitle }}</h2>
      <p>{{ termsContent.changesText }}</p>
      <br />
  
      <!-- 10. Governing Law -->
      <h2>{{ termsContent.governingLawTitle }}</h2>
      <p>{{ termsContent.governingLawText }}</p>
      <br />
  
      <!-- 11. Contact Information -->
      <h2>{{ termsContent.contactTitle }}</h2>
      <p>
        {{ termsContent.contactText }}<br />
        <strong>Email:</strong> operation@rosman.co.il
      </p>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue'
  
  const isHebrew = ref(true)
  const toggleLanguage = () => (isHebrew.value = !isHebrew.value)
  
  const termsContent = computed(() => {
    if (isHebrew.value) {
      return {
        title: "תנאים והגבלות",
        lastUpdated: "עודכן לאחרונה: 6 במרץ 2025",
        whoWeAreTitle: "מי אנחנו?",
        whoWeAreText: `רוסמן מרקט מפעיל פלטפורמה מקוונת ללוגיסטיקה פנימית,
          פרסום מוצרים, הצגת משרות פנויות, ומענה ללקוחות. השימוש באתר מהווה הסכמה לתנאים אלו.`,
        useWebsiteTitle: "שימוש באתר",
        useWebsitePoints: [
          "לגלוש באתר למטרות חוקיות בלבד.",
          "לא לעשות שימוש לרעה בטפסים או בבקשות עבודה.",
          "לא לנסות לבצע פריצה, ספאם או להפריע לפעילות התקינה של האתר."
        ],
        useWebsiteNote: "אם אינך מסכים לתנאים אלו, עליך להפסיק את השימוש באתר.",
        servicesTitle: "שירותים ותוכן באתר",
        servicesTable: `
            <table>
              <thead>
                <tr>
                  <th>עמוד</th>
                  <th>מטרה</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>עמוד הקטלוג</td>
                  <td>מציג מוצרים מסודרים לפי קטגוריות.</td>
                </tr>
                <tr>
                  <td>קטלוג מבצעים</td>
                  <td>רשימת מוצרים בהנחה.</td>
                </tr>
                <tr>
                  <td>עמוד סניפים</td>
                  <td>רשימת סניפים ושעות פעילות.</td>
                </tr>
                <tr>
                  <td>צור קשר</td>
                  <td>משתמשים יכולים לשלוח שם, אימייל וטלפון ליצירת קשר.</td>
                </tr>
                <tr>
                  <td>עמוד דרושים</td>
                  <td>הצגת משרות פנויות ואפשרות לשליחת קורות חיים ופרטי התקשרות.</td>
                </tr>
              </tbody>
            </table>
          `,
        servicesNote: "איננו מעבדים תשלומים או מוכרים מוצרים דרך האתר.",
        jobAppsTitle: "בקשות עבודה",
        jobAppsText: `בשליחת בקשה דרך עמוד הדרושים, אתה מאשר כי:
          <ul style="margin: 0; padding: 0 0 0 20px;">
            <li>אתה מספק מידע מדויק בטופס הבקשה.</li>
            <li>אנחנו עשויים ליצור קשר בפרטים שסיפקת.</li>
            <li>נתוני הבקשה יישמרו עד 6 חודשים או עד בקשת מחיקה.</li>
          </ul>
          שליחת בקשה אינה מבטיחה קבלה לעבודה.`,
        privacyPolicyTitle: "מדיניות פרטיות",
        privacyPolicyText: `אנו אוספים מידע אישי מוגבל לצורך מענה לפניות ועיבוד בקשות עבודה.
          לפרטים נוספים, עיין ב<a href="https://rosmannext.web.app/privacy" target="_blank" rel="noopener noreferrer">מדיניות הפרטיות</a>.`,
        ipTitle: "זכויות קניין רוחני",
        ipText: "כל התוכן באתר, כולל טקסט, לוגואים ותמונות, בבעלות רוסמן מרקט ואסור בשימוש ללא הרשאה.",
        thirdPartiesTitle: "שירותי צד שלישי",
        thirdPartiesText: `אנו משתמשים בשירותי צד שלישי לדוא"ל, Mailgun ו- Cloudflare.
          הם עשויים לעבד נתוני אימייל וטכניים בכפוף למדיניות הפרטיות שלהם:
          <ul style="margin: 0; padding: 0 0 0 20px;">
            <li><a href="https://www.mailgun.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Mailgun Privacy Policy</a></li>
            <li><a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">Cloudflare Privacy Policy</a></li>
          </ul>`,
        liabilityTitle: "הגבלת אחריות",
        liabilityText: "אנו שואפים לספק מידע מדויק אך איננו מתחייבים שהאתר יהיה נטול שגיאות או שהמידע בו תמיד מעודכן. איננו נושאים באחריות לנזקים עקיפים.",
        changesTitle: "שינויים בתנאים",
        changesText: "אנו רשאים לעדכן תנאים אלה בכל עת. יש לעיין בהם מעת לעת.",
        governingLawTitle: "דין וסמכות שיפוט",
        governingLawText: "התנאים כפופים לחוקי ישראל וכל סכסוך יידון בבתי המשפט בישראל.",
        contactTitle: "פרטי יצירת קשר",
        contactText: "לשאלות נוספות בנוגע לתנאים:",
      }
    } else {
      return {
        title: "Terms and Conditions",
        lastUpdated: "Last Updated: March 6, 2025",
        whoWeAreTitle: "1. Who We Are",
        whoWeAreText: `Rosman Supermarket operates an online platform for internal logistics, product listings, job postings, and customer inquiries. By using our website, you accept these Terms in full.`,
        useWebsiteTitle: "2. Use of Our Website",
        useWebsitePoints: [
          "Use the website only for lawful purposes.",
          "Do not misuse any forms or job applications.",
          "Do not attempt to hack, spam, or disrupt the site's services."
        ],
        useWebsiteNote: "If you do not agree to these terms, you must stop using the website immediately.",
        servicesTitle: "3. Services & Website Content",
        servicesTable: `
            <table>
              <thead>
                <tr>
                  <th>Page</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Catalog Page</td>
                  <td>Displays supermarket products by category.</td>
                </tr>
                <tr>
                  <td>Specials Catalog</td>
                  <td>Lists discounted products and promotions.</td>
                </tr>
                <tr>
                  <td>Branches Page</td>
                  <td>Lists supermarket branch locations and operating hours.</td>
                </tr>
                <tr>
                  <td>Contact Us Page</td>
                  <td>Allows users to submit name, email, and phone number for inquiries.</td>
                </tr>
                <tr>
                  <td>Wanted (Jobs) Page</td>
                  <td>Displays job openings and allows applicants to submit resumes and contact info.</td>
                </tr>
              </tbody>
            </table>
          `,
        servicesNote: "We do not process payments or sell products through this website.",
        jobAppsTitle: "4. Job Applications",
        jobAppsText: `By submitting a job application, you agree that:
          <ul style="margin: 0; padding: 0 0 0 20px;">
            <li>You provide accurate information.</li>
            <li>We may contact you using the details provided.</li>
            <li>Your application data will be stored for up to 6 months or deleted upon request.</li>
          </ul>
          Submitting an application does not guarantee employment.`,
        privacyPolicyTitle: "5. Privacy Policy",
        privacyPolicyText: `We collect limited personal data only to respond to inquiries and process job applications.
          For details, see our <a href="https://rosmannext.web.app/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,
        ipTitle: "6. Intellectual Property Rights",
        ipText: "All website content, including text, images, and logos, is owned by Rosman Supermarket and may not be used without permission.",
        thirdPartiesTitle: "7. Third-Party Services",
        thirdPartiesText: `We use third-party providers for email, Mailgun and Cloudflare.
          They may process technical and email-related data according to their policies:
          <ul style="margin: 0; padding: 0 0 0 20px;">
            <li><a href="https://www.mailgun.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Mailgun Privacy Policy</a></li>
            <li><a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">Cloudflare Privacy Policy</a></li>
          </ul>`,
        liabilityTitle: "8. Limitation of Liability",
        liabilityText: "We do our best to keep information accurate but do not guarantee error-free service or content. We are not liable for indirect damages.",
        changesTitle: "9. Changes to Terms",
        changesText: "We may update these Terms at any time. Please review them periodically.",
        governingLawTitle: "10. Governing Law",
        governingLawText: "These Terms are governed by the laws of Israel, and disputes shall be resolved in Israeli courts.",
        contactTitle: "11. Contact Information",
        contactText: "For additional questions regarding these Terms:",
      }
    }
  })
  </script>
  
  <style scoped>
  /* Base container styles */
  .terms-and-conditions {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: left; /* default LTR */
    color: white;
    direction: ltr;   /* default LTR */
  }
  
  .terms-and-conditions.rtl {
    text-align: right; /* switch to RTL for Hebrew */
    direction: rtl;
  }
  
  /* Toggle language button */
  .toggle-btn {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .toggle-btn i {
    margin-right: 8px;
  }
  .toggle-btn:hover {
    background-color: #0056b3;
  }
  
  /* Headings color */
  h1,
  h2 {
    color: #ddd;
  }
  
  /* Paragraph line spacing */
  p {
    line-height: 1.6;
  }
  
  /* Links inside v-html */
  :deep(a) {
    color: lightskyblue;
  }
  
  /* Basic table styling */
  :deep(table) {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }
  :deep(th),
  :deep(td) {
    border: 1px solid #666;
    padding: 8px;
    text-align: left; /* default LTR alignment */
  }
  
  /*
    Universal approach for ANY element coming from v-html:
    Force RTL or LTR on all children for .rtl vs not(.rtl)
  */
  .terms-and-conditions.rtl :deep(*) {
    direction: rtl !important;
    text-align: right !important;
  }
  .terms-and-conditions:not(.rtl) :deep(*) {
    direction: ltr !important;
    text-align: left !important;
  }
  </style>
  